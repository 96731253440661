* {
    box-sizing: border-box;
}

html::-webkit-scrollbar {
    width: 0.5vw;
    height: 0.05vw;
}

html::-webkit-scrollbar-thumb {
    background-color: var(--subtleText);
}

html::-webkit-scrollbar-thumb:hover {
    background-color: var(--maintext)
}


.scroll {

    &::-webkit-scrollbar {
        width: 0.75vw;
        height: 0.1vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--subtleText);


    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #777;

    }

    &::-webkit-scrollbar-track {
        background-color: rgba(--subtleText, 0.333);
    }
}

.submissionInformationContainer button:hover {
    background-color: rgba(0, 0, 0, 0);
}


.mainFont {
    font-family: var(--font);
}

.font500 {
    font-weight: 500;
}

.font400 {
    font-weight: 400;
}

.font300 {
    font-weight: 300;
}

.font200 {
    font-weight: 200;
}

.font100 {
    font-weight: 100;
}

.smallerMainFont {
    font-family: 'Fira Code', monospace;
    font-size: 1.5rem;
}


.userTitleContainer {

    align-self: flex-start;
    height: 140px;
    width: 100%;
}

.userTitleCard {
    width: 100%;
    height: 175px;
    align-self: center;
    padding-top: 50px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.profileContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.padLeft {
    padding-left: 2rem;
}


.grayText {
    color: rgb(127, 127, 127);
}

.wpmCard {
    display: flex;
    justify-content: space-evenly;
}


.dailySolutionGraph {
    margin-left: 10px;
    border-radius: 25px;
    margin-top: 75px;
    padding-top: 10px;
    width: 92%;
}

.graphContainer {

    margin-left: 30px;
    border-radius: 25px;
    margin-top: 75px;
    padding-top: 10px;
    width: 94%;
    height: 355px;


}


.submissionCard {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 400px;
}

.submissionContentContainer {


    width: 100%;
    height: 400px;

}

.submissionContainer {
    width: 100%;
    height: 350px;

}

.bestSubmissionsContainer {
    width: 100%;
}

.bestTitle {
    transform: translateX(-50px);

}

.recentSubmissionsContainer {
    width: 50%;
    margin-left: -70px;


}

.soltitle {
    font-size: 16px;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.trophyIcon {
    color: rgb(246, 249, 165);
    padding-top: 3px;
    font-size: 2vh;

}

.leaderboardSolutionContainer {
    background-color: rgb(31, 31, 35);
    width: 200%;
    height: 70px;
    margin-top: 25px;
    border-radius: 25px;
    align-self: center;


}

.podiumIcon {
    font-size: 20px;
}

.no-select {
    user-select: none;
}

.userInputContainer {
    padding-right: 10px;
}


.badgeContainer {
    text-align: center;
}


.solutionTitleDiv {
    background-color: rgba(85, 83, 83, 0.32);
    height: 5px;
    height: 50%;
    border-radius: 10px;
    padding-bottom: 5px;
}

.solutionTitleDiv button {
    background-color: rgba(0, 0, 0, 0);
    height: 60%;
    border-radius: 25px;
}

.solutionTitleDiv button:hover {
    background-color: rgba(0, 0, 0, 0);
    height: 60%;
    border-radius: 25px;
}

.badge {
    color: rgb(124, 197, 124);
    padding-right: 5px;
    user-select: none;
}

.submissionInformationContainer {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.submissionWPMDisplay {
    font-size: 1.5vh;
    color: rgb(181, 181, 181)
}


.generalUserInfo {
    width: 45%;
    display: flex;
    padding-right: 25px;
    padding-top: 8px;


}

.signoutButton {
    padding-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}

.bestSubmissionTitle {
    text-align: left;
}


#text {
    color: green;
    font-family: Courier New;
    font-size: 30px;
}


.Logo {
    display: flex;
    flex-direction: row;
}

.aboutContainer a {
    text-decoration: underline;
}

.glow {
    color: var(--maintext);
    text-shadow: 0px 0px 10px rgb(240, 229, 23), 0px 0px 5px rgb(252, 252, 25);
}

.settingsNavButton {
    color: rgb(146, 0, 0);
}

.nav {
    z-index: 50;
    font-family: var(--font);
    font-weight: 400;
    color: var(--subtleText);

    padding-top: 0.5rem;
    position: fixed;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
    width: 100%;

}

.underline {
    text-decoration: underline;

}

.loader {
    border: 8px solid var(--subtleText);
    border-radius: 50%;
    border-top: 8px solid var(--logoColor);
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.lbButton button:active {
    background-color: transparent;
}

.mainTextClass {
    color: var(--maintext);
}

.nav a.active:not(.site-title):not(.settingsNavButton) {
    color: var(--logoColor);

}

.standardButton button {
    color: var(--subtleText);
    background-color: transparent;
}

.standardButton button:hover {
    color: var(--maintext);
    background-color: transparent;
}

.standardButton button:active {
    color: var(--subtleText);
    background-color: transparent;
}

.nav ul {

    padding: 0;
    margin: 0;
    height: 100%;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav li:active {
}


.nav li:hover {
    color: var(--maintext);

}


.site-title {
    font-size: 2rem;
}


.correct {
    font-size: bold;
    color: var(--correctText) !important;
}

.incorrect {
    color: var(--incorrectText) !important;

}


.displayText {

    user-select: none;

}

.login-with-google-btn {
    transition: background-color .3s, box-shadow .3s;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #2f0505;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;

    &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }

    &:active {
        background-color: #eeeeee;
    }

    &:focus {
        outline: none;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
    }

    &:disabled {
        filter: grayscale(100%);
        background-color: #ebebeb;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
        cursor: not-allowed;
    }
}

.loginFont {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
}

.login-with-git-btn {
    transition: background-color .3s, box-shadow .3s;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #2f0505;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-image: url(https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;

    &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }

    &:active {
        background-color: #eeeeee;
    }

    &:focus {
        outline: none;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
    }

    &:disabled {
        filter: grayscale(100%);
        background-color: #ebebeb;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
        cursor: not-allowed;
    }
}


.displayText span {
    color: var(--subtleText);
    white-space: pre;
    user-select: none;
    word-break: break-all !important;

}


.activePage {
    background-color: #555;
}

.whiteText {
    color: rgb(244, 244, 244)
}


.pageContainer {
    margin: 1rem;
}

.aboutContainer {
    text-align: center;
    color: rgb(244, 244, 244)
}

.wordLimitButton {
    width: 32px;
    height: 30px !important;
    border-radius: 0px !important;
}

.title {

    display: flex;
    justify-content: center;
    background-color: rgb(210, 227, 243);
    border-radius: 100px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

:root {
    background-color: --chakra-colors-brand-100;
    --backgroundColor: #1f1f1f;
    --subtleText: #a8a8a8;
    --correctText: #d6d4d4;
    --incorrectText: #fabbbb;
    --caretColor: rgb(244, 244, 244);
    --maintext: rgb(244, 244, 244);
    --logoColor: #FFCD29;
    --themeActiveButton: #555;
    --font: 'Fira Code', monospace;

}

.backgroundColor {
    background-color: var(--backgroundColor);
}

.subtleTextColor {
    color: var(--subtleText);
}


.container {
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.content {
    margin-top: 20px;

    background-color: transparent;

    border-radius: 10px;
    height: fit-content;
    padding: 2rem;


}

.searchModal button {
    background-color: transparent;
    color: gray;
}

.searchModal button:hover {
    background-color: transparent;
    color: rgb(244, 244, 244);
}


.inputContainer {
    padding: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
}

.inputContainer button:focus {
    background-color: #555;
}

.restartDiv button {
    background-color: rgba(0, 0, 0, 0.2);
    color: aliceblue;
    padding-left: 0px;
    order: 1;
}

.restartDiv button:hover {
    background-color: rgba(0, 0, 0, 0.405);
    padding-left: 0px;
    order: 1;
}

.restartDiv button:focus {
    background-color: rgba(0, 0, 0, 0.405);
    padding-left: 0px;
    order: 1;
}

.userInput {

}


.restartDiv button {

}


.text {
    margin-top: 25px;
    width: 90%;
    border-color: black;
    font-family: 'Inconsolata', sans-serif;


}


#userInput {
    text-align: center;
}

.textInput {
    background-color: transparent;
    outline: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    position: absolute;
    border-radius: 10px;
    font-size: 30px;
    border-radius: 10px;
    border: 0px;
    caret-color: transparent;

    color: transparent;

    font-family: 'Fira Code', monospace;

}

.nocursor {
    cursor: none;
}

.textInput input {
    user-select: none;
}

.textContainer {
    text-align: center;
}

.hide {
    color: transparent;
}

.behindCursor {
    box-shadow: -3px 0 0 0 white;
    animation: blinkbehind 1s infinite;
}

.nounderline {
    text-decoration-color: transparent;
}

@keyframes blinkbehind {
    0% {
        box-shadow: -3px 0 0 0 var(--caretColor);
    }
    25% {
        box-shadow: -3px 0 0 0 var(--caretColor);
    }
    50% {
        box-shadow: -3px 0 0 0 transparent;
    }
    75% {
        box-shadow: -3px 0 0 0 var(--caretColor);
    }
    100% {
        box-shadow: -3px 0 0 0 var(--caretColor);
    }
}

.newcursor {
    box-shadow: 4px 0 0 0 var(--caretColor);


}

@keyframes blink {
    0% {
        box-shadow: 3px 0 0 0 var(--caretColor);
    }
    25% {
        box-shadow: 3px 0 0 0 var(--caretColor);
    }
    50% {
        box-shadow: 3px 0 0 0 transparent;
    }
    75% {
        box-shadow: 3px 0 0 0 var(--caretColor);
    }
    100% {
        box-shadow: 3px 0 0 0 var(--caretColor);
    }
}

.textContainer input:focus {
    text-align: center;
    background-color: rgba(244, 244, 244, 0.12);
}


#userInput button {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    width: 50px;
    height: 50px;
    border: 0px;
    font-size: 25px;
    border-radius: 10px;
    background-color: rgb(247, 247, 227);
    background: rgba(255, 255, 255, 0.12);
    color: var(--maintext);
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.19);
    border-radius: 15px;

}

.wordsLeft {
    color: rgb(244, 244, 244);
    padding-top: 5px;
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#userInput button:hover {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background: rgba(207, 207, 207, 0.12);

}

.maxWordsDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding-right: 5%;
    padding-left: 25px;
}

.maxWordsDiv input {
    background-color: transparent;
    align-self: center;
    user-select: none;
    outline: none;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    font-size: 30px;
    border-radius: 10px;
    border: 0px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.placeholder input::placeholder {
    color: var(--subtleText);
}

.maxWordsDiv input::placeholder {
    transform: translateY(-5px);
    font-size: 30px;
}

.maxWordsDiv input:focus {
    background-color: transparent;
    user-select: none;
    outline: none;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    font-size: 30px;
    border-radius: 10px;
    border: 0px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.error {
    color: rgb(215, 48, 48);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font: bold;
}


.codingSettings Button {
    align-self: center;
    height: 30px;
    width: 100px;
    border-radius: 0px;
}


.languageSettings Button {
    color: rgb(0, 0, 0);
}

.codingSettings {
    display: flex;
    justify-content: center;
}


#userInput {
    padding-top: 25px;
}

.leetcodeTitle {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 30px;
    text-align: center;
    color: whitesmoke;


}

.smallerText {
    font-size: 20px;
    color: var(--maintext);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: #555;
    align-items: center;
    text-align: center;
}

.trashIcon {
    color: var(--subtleText);
    font-size: 24px;
}

#timer {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 25px;
    text-align: center;
    color: rgb(244, 244, 244)
}

.trashButton button:hover {

    background-color: transparent;
}

.TimerAndInputContainer {
    width: 1000px;
    display: grid;
    justify-content: flex-start;
}

.reminder {
    font-style: italic;
    color: var(--maintext);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    user-select: none;
}

.wpm {
    font-size: 100px;
    color: #777;
}

.currentCorrect {
    /* color: rgb(124, 197, 124);
    font-weight: bold; */
    color: var(--correctText) !important;
}

.underlineRed {
    text-decoration: underline;
    text-decoration-color: var(--incorrectText);
}

.currentIncorrect {
    color: var(--incorrectText) !important;
}

.maintext {
    color: var(--maintext);
}

.whiteUnderline {
    text-decoration: underline;
    text-decoration-color: var(--maintext);
}




